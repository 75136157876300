<template>
  <v-container fluid class='report-card align-self-sm-stretch justify-space-around'>
    <v-row class='d-flex justify-center card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between align-self-center'>
        <span class='report-title'> {{ $t('DIGITALIZAO_FICHA_REPORT_TITLE') }} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
      <v-col cols='12'>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class='d-flex justify-center align-stretch font-weight-black content-value primary--text display-3'>
      <span> {{ value }} </span>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    data () {
      return {
        reportName: 'Digitalização Fichas',
        value: null,
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
          },
        });
        this.value = data && data.data && data.data[0] ? data.data[0].total : null;
      },
      removeWidget (id) {
        document.removeItem(id);
      },
    },
  };
</script>
<style>
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .card-title {
    min-height: 30%;
  }
</style>
